<template>
    <div id="app">
        <NavBar/>
        <div style="height: 12.6vh; width: 100%;"></div>
        <router-view/>
        <FooterBar/>
    </div>
</template>
<script>
import NavBar from '@/components/NavBar.vue'
import FooterBar from '@/components/FooterBar.vue'
import { useDataStore } from '@/data/datastore.js'
export default {
    name: 'App',
    components: {
        NavBar,
        FooterBar,

    },
    data() {
        return {
            dataStore: useDataStore()
        }
    },
    methods: {

    },
    created: function() {
        this.dataStore.setModalOpen(false)
    }

}
</script>
<style lang="scss">
    @use '@/assets/node.scss' as app;
</style>
