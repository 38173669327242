<template>
    <footer>
        <div class="footer-item">
            <img src="https://cdn.nodenium.com/98893dc5-a738-4f82-9ef6-5c145a993128" alt="Nodenium Logo" class="footer-logo"/>
            <p>© {{ new Date().getFullYear() }} - All rights reserved.</p>
            <div class="socials">
            
            </div>
        </div>
        <div class="footer-item">
            <h5>Social</h5>
            <ul>
                <li><a target="_blank" href="https://nodenium.com/discord">Discord</a></li>
                <li><a target="_blank" href="https://www.instagram.com/project_nodenium/">Instagram</a></li>
                <li><a target="_blank" href="https://www.reddit.com/r/ProjectNodenium/">Reddit</a></li>
                <li><a target="_blank" href="https://www.youtube.com/@projectnodenium">Youtube</a></li>
                <li><a target="_blank" href="https://www.pinterest.com/projectnodenium">Pinterest</a></li>
            </ul>
        </div>
        <div class="footer-item mobile-hidden">
            <h5>Resources</h5>
            <ul>
                <li><router-link to="/technical">Server Info</router-link></li>
                <li><router-link to="/status">Server Status</router-link></li>
            </ul>
        </div>
        <div class="footer-item mobile-hidden">
            <h5>Company</h5>
            <ul>
                <li><router-link to="/about">About Us</router-link></li>
                <li><a target="_blank" href="https://cdn.nodenium.com/tos.pdf">Terms Of Service</a></li>
                <li><a target="_blank" href="https://cdn.nodenium.com/privacy.pdf">Privacy Policy</a></li>
                <li><a target="_blank" href="https://cdn.nodenium.com/brand.pdf">Brand Use</a></li>
            </ul>
        </div>
        <div class="footer-item mobile-hidden">
            <h5>Staff</h5>
            <ul>
                <!-- <li><router-link to="/members">Our Team</router-link></li> -->
                <li><router-link to="/admin">Admin Hub</router-link></li>
            </ul>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterBar',
    components: {
    }
}
</script>

<style lang="scss">
    @use '@/assets/node.scss' as app;

    footer {
        position: relative;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: app.$col-footer;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: flex-start;
        padding: 4rem 1rem;

        @media (max-width: app.$mobile) {
            flex-direction: column;
            align-items: center;
            padding: 1rem 1rem;
        }

        .footer-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            color: white;

            @media (max-width: app.$mobile) {
                margin: 1rem 0;
                align-items: center;
                text-align: center;

                img {
                    margin: 0 0 1rem 0;
                }
            }

            h5 {
                margin: 0 0 1rem 0;
            }
            ul {
                list-style: none;
                padding: 0;
                li {
                    margin: 0 0 1rem 0;
                    cursor: pointer;
                    a {
                        color: white;
                        text-decoration: none;
                        transition: all 0.3s ease;
                        &:hover {
                            color: app.$col-primary;
                        }
                    }
                }
            }
        }

        .footer-logo {
            width: 100px;
            height: 100px;
        }

        .socials {
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 100%;
            a {
                color: white;
                text-decoration: none;
                transition: all 0.3s ease;
                &:hover {
                    color: app.$col-primary;
                }
            }
        }
    }
</style>