<template>
    <nav class="top-nav" :style="{height: h + 'vh'}">
        <a href="/"><img src="https://cdn.nodenium.com/5bcf1aa3-0e9e-4b55-b8a8-03eeb5c39ce1" alt="Nodenium logo"/></a>
        <ul>
            <li v-for="(page, name) in pages" :key="name">
                <template v-if="typeof page === 'string'">
                    <router-link :to="page">{{name}}</router-link>
                </template>
                <template v-else>
                    <a href="#" class="submenu-title">{{name}}</a>
                    <div class="submenu">
                        <router-link v-for="(subpage, subname) in page" :key="subname" :to="subpage">{{subname}}</router-link>
                    </div>
                </template>
            </li>
            <!-- <li>
                <CustomButton text="Join Our Discord" link="https://nodenium.com/discord" discord newtab/>
            </li> -->
        </ul>
    </nav>
    <nav class="side-nav">
        <div class="whitespace" @click="menuOpen=false" :class="{open: menuOpen}"></div>

        <div :class="{menuIcon:true, shift:menuOpen}" @click="menuOpen = !menuOpen">
            <IconButton :icon="menuOpen?'arrow_back_ios_new':'menu'"/>
        </div>
        <ul :class="{open: menuOpen}" @click="clickOnAny">
            <li v-for="(page, name) in pages" :key="name">
                <template v-if="typeof page === 'string'">
                    <router-link :to="page" @click="menuOpen=false">{{name}}</router-link>
                </template>
                <template v-else>
                    <a href="#" class="submenu-title" @click.stop="submenu = (submenu===page?null:page)">{{name}}</a>
                    <div class="submenu" v-if="submenu === page">
                        <router-link v-for="(subpage, subname) in page" :key="subname" :to="subpage" @click="menuOpen=submenu=false">{{subname}}</router-link>
                    </div>
                </template>
            </li>
            <li>
                <CustomButton text="Discord" link="https://nodenium.com/discord" discord newtab/>
            </li>
        </ul>
    </nav>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue';
import IconButton from './IconButton.vue';
export default {
    name: 'NavBar',
    components: {
        CustomButton,
        IconButton
    },
    data() {
        return {
            h: 12.6,
            menuOpen: false,
            submenu: null,
            pages: {
                'Home': "/",
                'Builds': "/builds",
                'About': "/about",
                // 'Store': "/store",
                'Maps': {
                    'Survival Server': "/MainMap",
                    'Testing Server': "/TestMap",
                    'Plots Server': "/PublicMap",
                },
                'Server': {
                    // 'Members': "/members",
                    'Server History': "/history",
                    'Server Status': "/status",
                    'Archive': "/archive",
                    'Commands': "/commands",

                },
                // 'Resources': {
                //     // 'Mods': "/mods",
                //     // 'Technical': "/technical",
                //     // 'Tools': "/tools"
                // }
            }
        }
    },
    methods: {
        handleScroll() {
            let scrollTop = window.scrollY
            if (scrollTop > 0) {
                this.h = 8
            } else {
                this.h = 12.6
            }
        },
        clickOnAny() {
            this.submenu = null;
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll)
    },
}
</script>

<style lang="scss" scoped>
    @use '@/assets/node.scss' as app;

    .discord-icon {
        width: 20px;
        height: 20px;
        margin: 0 0 0 1rem;
    }
    .top-nav {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        background-color: app.$col-primary;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        width: 100%;
        height: 12.6vh;
        transition: height 0.3s ease-in-out;


        @media (max-width: app.$mobile) {
            display: none;
        }

        img {
            width: 30px;
            margin-left: 1rem;
        }

        ul {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            list-style: none;
            padding: 0;
            width: 80%;
            
            li {
                position: relative;
                user-select: none;
                a {
                    color: app.$col-text;
                    text-decoration: none;
                    &:hover {
                        color: app.$col-tertiary;
                    }
                }
                .submenu {
                    display: none;
                    position: absolute;
                    width: 10rem;
                    background-color: app.$col-text;
                    a {
                        display: block;
                        padding: 1rem;
                        text-align: left;
                        text-decoration: none;
                        text-wrap: nowrap;
                        color: app.$col-primary;
                        &:hover {
                            color: app.$col-accent;
                        }
                    }
                }
                &:hover .submenu {
                    display: block;
                }
                .submenu-title {
                    cursor: pointer;

                    &:hover {
                        color: app.$col-tertiary;
                    }

                    &::after {
                        content: ' ⏷';
                    }
                }

                .cta {
                    background-color: app.$col-accent;
                    color: app.$col-text;
                    padding: 1rem;
                    display: flex;
                    align-items: center;
                    &:hover {
                        background-color: app.$col-tertiary;
                    }
                }
            }

            .highlight {
                text-decoration: underline;
            }
        }
    }

    .side-nav {
        display: none;
        position: fixed;
        top: -2px;
        left: -2px;
        z-index: 100;
        width: calc(80vw + 4px);
        height: calc(100vh + 4px);
        pointer-events: none;

        @media (max-width: app.$mobile) {
            display: block;
        }

        .menuIcon {
            position: fixed;
            cursor: pointer;
            pointer-events: all;
            top: 1rem;
            left: 1rem;
            z-index: 101;
            .icon {
                color: app.$col-text;
                font-size: 2rem;
                transition: transform .2s ease-in-out;
            }

            &.shift {
                .icon {
                    transform: rotate(360deg);
                }
            }
        }

        ul {
            pointer-events: all;
            display: flex;
            position: fixed;
            flex-direction: column;
            justify-content: space-around;
            align-items: flex-start;
            flex-wrap: nowrap;
            list-style: none;
            padding: 2rem 0 0 2rem;
            transform: translateX(-100%);
            background-color: app.$col-primary;
            width: 80vw;
            height: 100vh;
            z-index: 100;
            transition: transform 0.3s ease-in-out;

            &.open {
                transform: translateX(0);
            }

            li {
                position: relative;
                margin: 0 3.5rem;
                user-select: none;
                a {
                    color: app.$col-text;
                    text-decoration: none;
                    &:hover {
                        color: app.$col-tertiary;
                    }
                }
                .submenu {
                    display: block;
                    position: relative;
                    width: 10rem;
                    a {
                        display: block;
                        padding: 1rem 0 1rem 2rem;
                        text-align: left;
                        text-decoration: none;
                        text-wrap: nowrap;
                        color: app.$col-text;
                        &:hover {
                            color: app.$col-accent;
                        }
                    }
                }
                .submenu-title {
                    cursor: pointer;

                    &:hover {
                        color: app.$col-tertiary;
                    }

                    &::after {
                        content: ' ⏷';
                    }
                }

                .cta {
                    background-color: app.$col-accent;
                    color: app.$col-text;
                    padding: 1rem;
                    display: flex;
                    align-items: center;
                    &:hover {
                        background-color: app.$col-tertiary;
                    }
                }
            }

            .highlight {
                text-decoration: underline;
            }
        }
        .whitespace {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 99;
            width: 100vw;
            height: 100vh;
            background-color: rgba(0, 0, 0, 0.5);
            transition: opacity 0.3s ease-in-out;
            opacity: 0;
            pointer-events: none;
            &.open {
                opacity: 1;
                pointer-events: all;

            }
        }
    }
</style>