<template>
    <div class="banner" :style="(color[0]==='#')?({backgroundColor: color}):('')">
        <h1 class="centered-text h0">{{ text }}</h1>
        <div class="banner-content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WidthBanner',
    components: {
    },
    props: {
        color: {
            type: String,
            default: 'accent'
        },
        text: {
            type: String,
            default: 'DEFAULT TEXT'
        }
    }
}
</script>

<style lang="scss" scoped>
    @use '@/assets/node.scss' as app;
    .banner {
        background-color: app.$col-accent;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 3rem 0;
        margin: 4rem 0;
        h1 {
            color: app.$col-text;
        }
        .banner-content{
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
        }


        @media (max-width: app.$mobile){
            margin: 2rem 0;
        }
    }
</style>