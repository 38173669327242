<template>
    <span class="material-symbols-sharp icon noselect" :style="{fontSize: size}" draggable="false">{{icon}}</span>
</template>

<script>

export default {
    name: 'IconButton',
    props: {
        icon: {
            type: String,
            required: true
        },
        size: {
            type: String,
            default: '1.5rem'
        }
    }
}
</script>

<style scoped lang="scss">
    .icon {
        cursor: pointer;
        padding: 0.5rem;
        border-radius: 50%;
        transition: all 0.1s ease-in-out;
        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
</style>