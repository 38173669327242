<template>
    <div class="gallery-grid">
        <div class="gallery-grid__item" v-for="image in images" :key="image.id" @click="setSelectedImage(image)">
            <img :src="'https://cdn.nodenium.com/'+image.images[0]" :alt="image.title" draggable="false"/>
        </div>
        <GalleryModal v-if="selectedImage" :selectedImage="selectedImage" @close="setSelectedImage(null)"/>

    </div>
</template>

<script>
    import GalleryModal from './GalleryModal.vue';
    import { useDataStore } from '@/data/datastore.js';

    export default {
        name: 'GalleryGrid',
        components: {
            GalleryModal
        },
        props: {
            gallery: String
        },
        data() {
            return {
                selectedImage: null,
                dataStore: useDataStore(),
                images: []
            }
        },
        methods: {
            setSelectedImage(image) {
                this.selectedImage = image
                this.dataStore.setModalOpen(image !== null)
                console.log(this.images)
            },
            async getImages() {
                let url = 'https://cdn.nodenium.com/dbget/builds'
                let json = await fetch(url).then(res => res.json())
                this.images = json.filter(image => image.tags.includes(this.gallery))
            }
        },
        created() {
            this.getImages()
        }

    }
</script>

<style lang="scss" scoped>
    @use '@/assets/node.scss' as app;
    .gallery-grid {
        padding: 1rem;
        
        display: grid;
        grid-template-columns: repeat(3, minmax(300px, 1fr));
        gap: 1rem;

        @media (max-width: app.$mobile) {
            grid-template-columns: 1fr;
            width: 100%;
        }
    }

    .gallery-grid__item {
        img {
            width: 100%;
            aspect-ratio: 1 / 1;
            object-fit: cover;
            scale: 1;
            transition: all 0.1s ease-in-out;
            box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);

            &:hover {
                cursor: pointer;
                scale: 1.02;

            }
        }
    }
</style>