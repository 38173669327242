<template>
    <div class="gallery-modal">
        <div class="modal">
            <div class="close-button">
                <IconButton icon="close" @click="$emit('close')"/>
            </div>
            <div class="info">
                <div class="main-info">
                    <h2>{{selectedImage.season}}</h2>
                    <h1>{{selectedImage.title}}</h1>
                    <p>{{selectedImage.description.replace(/(?:\r\n|\r|\n)/g, '<br>')}}</p>
                </div>
                <div class="builders" v-if="selectedImage.builders.length > 0">
                    <hr>
                    <h4>Builders</h4>
                    <div class="pfp-list">
                        <div class="pfp" v-for="(builder, index) in selectedImage.builders" :key="index">
                            <img :src="getPfpImage(builder)"
                            :alt="builder"
                            draggable="false">
                            <p>{{ builders[index] }} </p>
                        </div>
                    </div>
                </div>
                <div class="photographer">
                    <p>📸 {{ selectedImage.credit }}</p>
                </div>
            </div>
            <div class="images">
                <img :src="'https://cdn.nodenium.com/'+selectedImage.images[imageIndex]" :alt="selectedImage.title" draggable="false"/>
                <div class="image-selector">
                    <img v-for="(image, index) in selectedImage.images" :src="'https://cdn.nodenium.com/'+image" :alt="selectedImage.title" :key="index" :class="{selected: imageIndex === index}" @click="imageIndex = index" draggable="false"/>
                </div>
            </div>
        </div>
        <div class="backdrop" @click="$emit('close')"></div>
    </div>
</template>

<script>
    import IconButton from '@/components/IconButton.vue';
    export default {
        name: 'GalleryModal',
        components: {
            IconButton
        },
        props: {
            selectedImage: Object
        },
        data() {
            return {
                imageIndex: 0,
                builders: []
            }
        },
        methods: {
            getPfpImage(uuid){
                return "https://crafatar.com/avatars/"+uuid+"?size=64";
            },
            async getUsernames(uuid){
                let url = "https://api.minetools.eu/uuid/"+uuid;
                let response = await fetch(url);
                let data = await response.json();
                return data.name;
            }
        },
        async mounted(){
            for(let i = 0; i < this.selectedImage.builders.length; i++){
                let username = await this.getUsernames(this.selectedImage.builders[i]);
                this.builders.push(username);
            }
        },
    }
</script>

<style lang="scss" scoped>
@use '@/assets/node.scss' as app;
    .gallery-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;


        .backdrop {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
        }

        .modal {
            width: 80vw;
            height: 90vh;
            z-index: 10;
            background-color: app.$col-primary;
            box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;

            @media (max-width: app.$mobile) {
                flex-direction: column-reverse;
                width: 100vw;
                height: 100%;
                position: fixed;
                top: -1px;
                left: 0;
                overflow-y: scroll;
            }
            
            .close-button {
                position: relative;
                top: 1rem;
                left: 1rem;

                @media (max-width: app.$mobile) {
                    position: fixed;
                    top: .5rem;
                    left: .5rem;
                }
            }

            .images{
                width: 63%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                @media (max-width: app.$mobile) {
                    margin-top: 3rem;
                    width: 100%;
                    height: 50%;
                }

                img {
                    width: 90%;
                    object-fit: cover;
                    aspect-ratio: 16/9;

                    @media (max-width: app.$mobile) {
                        aspect-ratio: 4/3;
                    }
                }

                .image-selector {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    width: 90%;
                    margin-top: 1rem;
                    img {
                        width: 15%;
                        margin-right: 1rem;
                        aspect-ratio: 1/1;
                        object-fit: cover;
                        scale: 1;
                        transition: all 0.1s ease-in-out;
                        border: 2px solid transparent;

                        &:hover {
                            cursor: pointer;
                            scale: 1.02;
                            border: 2px solid app.$col-text;
                        }
                    }

                    .selected {
                        border: 2px solid app.$col-accent;
                    }
                }
            }

            .info {
                width: 37%;
                padding: 2rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;

                @media (max-width: app.$mobile) {
                    width: 100%;
                    padding: 1rem;
                }

                .main-info {
                    h2 {
                        font-size: 2rem;
                        margin: 0;
                    }
                }

                .builders {
                    hr {
                        width: 100%;
                        margin: 1rem 0;
                        border: 0;
                        border-top: 1px solid app.$col-text;
                    }
                    .pfp-list {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        flex-wrap: wrap;
                        gap: 1rem;
                        max-height: 20rem;
                        overflow-y: hidden;
                        .pfp {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            img {
                                width: 4rem;
                                height: 4rem;
                                object-fit: cover;
                            }
                            &:hover {
                                p {
                                    display: block;
                                }
                            }
                            p {
                                display: none;
                                position: absolute;
                                background-color: app.$col-secondary;
                                transform: translateY(3rem);
                                padding: .5rem 1rem;
                            }
                        }
                    }
                }

                .photographer {
                    p {
                        position: absolute;
                        bottom: 5vh;
                        left: 10vw;
                        margin: 1rem;

                        @media (max-width: app.$mobile) {
                            position: relative;
                            bottom: 0;
                            left: 0;
                        }
                    }
                }
            }
        }
    }
</style>