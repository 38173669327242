<template>
    <div class="home">
        <div class="header-bar">
            <img src="https://cdn.nodenium.com/034ccfc6-8fc8-4ecd-9e83-1bfbf01515b2" alt="Nodenium Header Image" draggable="false" ref="herobanner"/>
            <div class="header-content">
                <h1 class="centered-text h0">Take your builds to the next level.</h1>
                <h5 class="centered-text ">Nodenium is a haven for builders who want to create amazing things in Minecraft.</h5>
                <div class="buttons">
                    <CustomButton text="OUR STORY" link="/about" solid light bold spaced class="mobile-hidden"/>
                    <CustomButton text="JOIN DISCORD" link="https://nodenium.com/discord" discord newtab bold spaced/>
                </div>
            </div>
        </div>
        <section class="home-content">
            <main>
                <FloatingBanner>
                    <SplitSection class="mobile-hidden" justify="space-evenly" ratio="50%">
                        <template v-slot:left>
                            <h3 class="text-inverse">Who we are</h3>
                            <p class="text-inverse">
                                Nodenium is a group of highly skilled and dedicated builders who strive to make amazing creations in survival Minecraft!
                                <br>
                                <br>
                                Join our public Discord or apply for our SMP to get in on the building and hang out with the community!
                            </p>
                            <div class="buttons">
                                <CustomButton text="Join Discord" link="https://nodenium.com/discord" newtab outline dark spaced/>
                                <CustomButton text="Apply for SMP" link="https://nodenium.com/apply" newtab outline dark spaced/>
                            </div>
                        </template>
                        <template v-slot:right>
                            <img src="https://cdn.nodenium.com/6de4b496-1c26-4526-aa69-3622b85a0ca0" alt="Nodenium Hero Image" draggable="false" class="floating-img"/>
                        </template>
                    </SplitSection>
                    <div class="mobile-visible">
                        <h3 class="text-inverse">Who we are</h3>
                        <p class="text-inverse">
                            Nodenium is a group of highly skilled and dedicated builders who strive to make amazing creations in survival Minecraft!
                            <br>
                            <br>
                            Join our public Discord or apply for our SMP to get in on the building and hang out with the community!
                        </p>
                        <div class="buttons">
                            <CustomButton text="Join Discord" link="https://nodenium.com/discord" newtab outline dark spaced/>
                            <CustomButton text="Apply for SMP" link="https://nodenium.com/apply" newtab outline dark spaced/>
                        </div>
                    </div>
                </FloatingBanner>
                <div class="build-showcase">
                    <h3>Community Creations</h3>
                    <h5>Check out some of the amazing builds our community has created!</h5>
                    <GalleryGrid :gallery="'homepage'"/>
                </div>
                <CustomButton text="More Builds" link="/builds" outline light bold/>
                <WidthBanner text="Ready to join in on the action?">
                    <CustomButton text="Join Our Discord" link="https://nodenium.com/discord" newtab solid light bold spaced/>
                    <CustomButton text="Apply for SMP" link="https://nodenium.com/apply" newtab solid light bold spaced/>
                </WidthBanner>
            </main>
        </section>
    </div>
</template>

<script>
    import CustomButton from '@/components/CustomButton.vue';
    import GalleryGrid from '@/components/GalleryGrid.vue';
    import WidthBanner from '@/components/WidthBanner.vue';
    import FloatingBanner from '@/components/FloatingBanner.vue';
import SplitSection from '@/components/SplitSection.vue';
export default {
    name: 'HomeView',
    components: { CustomButton, GalleryGrid, WidthBanner, FloatingBanner, SplitSection
    },
    data() {
        return {
            heroBanner: null
        }
    },
    methods: {
        parallax(e) {
            if (!this.$refs.herobanner) return;
            if(window.innerWidth < 900) return;
            let x = ((e.clientX / window.innerWidth) - 0.5) * 2;
            let y = ((e.clientY / window.innerHeight) - 0.5) * 2;
            this.$refs.herobanner.style.transform = `translate(${x * 10}px, ${y * 10}px)`;
        }
    },
    mounted() {
        //window.addEventListener('mousemove', this.parallax);
    },
    beforeUnmount() {
        //window.removeEventListener('mousemove', this.parallax);
    }
}
</script>
<style lang="scss" scoped>
    @use '@/assets/node.scss' as app;

    .home-content{
        width: 100%;
        background-color: app.$col-primary;
        overflow: visible;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;

        .floating-img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 1rem;
        }
    }

    .header-bar {
        width: 100%;
        height: 90vh;
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .header-content {
            width: 100%;
            height: calc(100% - 80px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: fixed;
            top: 0;
            left: 0;
            z-index: -1;
            filter: brightness(0.7) saturate(0.9)
        }
    
    }

    .buttons {
        display: flex;
        margin-top: 2rem;
    }


    .build-showcase {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h3,h5 {
            text-align: center;
        }
    }


</style>