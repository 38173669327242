import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/AboutView.vue')
    },
    {
        path: '/MainMap',
        name: 'MainMap',
        component: () => import('../views/DynmapView.vue'),
        props: {
            name: 'Main Server Map',
            link: 'https://map_project.nodenium.com/'
        }
    },
    {
        path: '/TestMap',
        name: 'TestMap',
        component: () => import('../views/DynmapView.vue'),
        props: {
            name: 'Test Server Map',
            link: 'https://map_test.nodenium.com/'
        }
    },
    {
        path: '/PublicMap',
        name: 'PublicMap',
        component: () => import('../views/DynmapView.vue'),
        props: {
            name: 'Public Server Map',
            link: 'https://map_public.nodenium.com/'
        }
    },
    {
        path: '/builds',
        name: 'builds',
        component: () => import('../views/BuildsView.vue')
    },
    {
        path: '/history',
        name: 'history',
        component: () => import('../views/HistoryView.vue')
    },
    {
        path: '/store',
        name: 'store',
        component: () => import('../views/StoreView.vue')
    },
    {
        path: '/members',
        name: 'members',
        component: () => import('../views/MembersView.vue')
    },
    {
        path: '/status',
        name: 'status',
        component: () => import('../views/StatusView.vue')
    },
    {
        path: '/mods',
        name: 'mods',
        component: () => import('../views/ModsView.vue')
    },
    {
        path: '/technical',
        name: 'technical',
        component: () => import('../views/TechnicalView.vue')
    },
    {
        path: '/archive',
        name: 'archive',
        component: () => import('../views/ArchiveView.vue')
    },
    {
        path: '/tools',
        name: 'tools',
        component: () => import('../views/ToolsView.vue')
    },
    {
        path: '/admin',
        name: 'admin',
        component: () => import('../views/AdminView.vue')
    },
    {
        path: '/commands',
        name: 'commands',
        component: () => import('../views/CommandsView.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'notFound',
        component: () => import('../views/NotFoundView.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
