// pinia datastore

import { defineStore } from 'pinia'

export const useDataStore = defineStore('data', {
    state: () => {
        return {
            modalOpen: false
        }
    },
    actions: {
        setModalOpen(value) {
            this.modalOpen = value
            document.body.style.overflowY = value ? 'hidden' : 'auto';
            document.body.style.overflowX = 'hidden';
        }
    },
})
