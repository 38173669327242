<template>
    <button 
        :class="'custom-button ' +  (bold ? 'bold ' : '') + (discord ? 'discord ' : '') +(spaced ? 'spaced ' : '') +  (solid ? 'solid' : '') + (outline ? 'outline' : '') + (light ? '-light ' : '') + (dark ? '-dark ' : '') + (warning ? '-warning ' : '') + (error ? '-error ' : '') + (success ? '-success ' : '')"
        @click="click" >
        <h6>{{ text }}</h6>
    </button>
</template>

<script>
export default {
    name: 'CustomButton',
    props: {
        text: {
            type: String,
            required: true,
            default: 'DEFAULT TEXT'
        },
        link: {
            type: String,
            required: false
        },
        newtab: {
            type: Boolean,
            default: false,
            required: false
        },
        light: {
            type: Boolean,
            default: false,
            required: false
        },
        dark: {
            type: Boolean,
            default: false,
            required: false
        },
        solid: {
            type: Boolean,
            default: false,
            required: false
        },
        outline: {
            type: Boolean,
            default: false,
            required: false
        },
        bold: {
            type: Boolean,
            default: false,
            required: false
        },
        discord: {
            type: Boolean,
            default: false,
            required: false
        },
        warning: {
            type: Boolean,
            default: false,
            required: false
        },
        error: {
            type: Boolean,
            default: false,
            required: false
        },
        success: {
            type: Boolean,
            default: false,
            required: false
        },
        spaced: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    methods: {
        click() {
            if (this.link) {
                window.open(this.link, this.newtab ? '_blank' : '_self');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @use '@/assets/node.scss' as app;
    .custom-button {
        margin: 0;
        height: 3rem;
        display: inline-flex;
        align-items: center;
        background-color: app.$col-primary;
        color: app.$col-text;
        padding: 2rem 2rem;
        border: none;
        cursor: pointer;
        transition: all 0.3s ease;
        line-height: 0;
        width: fit-content;
        &:hover {
            background-color: app.$col-secondary;
        }

        h6 {
            text-wrap: nowrap;
            margin-bottom: 0.25rem;
            transition: all 0.3s ease;
        }
    }

    // BUtton Types:
    // join discord
    // solid light, solid dark, outline light, outline dark
    .spaced {
        margin: 0 1rem;
    }
    

    .solid{
        &-light {
            background-color: app.$col-text;
            color: app.$col-primary;
            h6 {
                color: app.$col-primary;
            }
            &:hover {
                background-color: app.$col-tertiary;
                h6 {
                    color: app.$col-text;
                }
            }
        }
        &-dark {
            background-color: app.$col-secondary;
            h6 {
                color: app.$col-text;
            }
            &:hover {
                background-color: app.$col-primary;
                h6 {
                    color: app.$col-text;
                }
            }
        }
        &-warning {
            background-color: app.$col-warning;
            h6 {
                color: app.$col-text;
            }
            &:hover {
                background-color: app.$col-error;
                h6 {
                    color: app.$col-text;
                }
            }
        }
        &-error {
            background-color: app.$col-error;
            h6 {
                color: app.$col-text;
            }
            &:hover {
                background-color: app.$col-warning;
                h6 {
                    color: app.$col-text;
                }
            }
        }
        &-success {
            background-color: app.$col-success;
            h6 {
                color: app.$col-text;
            }
            &:hover {
                background-color: app.$col-warning;
                h6 {
                    color: app.$col-text;
                }
            }
        }
    }

    .outline {
        &-light {
            background-color: transparent;
            border: 2px solid app.$col-text;
            h6 {
                color: app.$col-text;
            }
            &:hover {
                background-color: app.$col-text;
                h6 {
                    color: app.$col-primary;
                }
            }
        }
        &-dark {
            background-color: transparent;
            border: 2px solid app.$col-secondary;
            h6 {
                color: app.$col-secondary;
            }
            &:hover {
                background-color: app.$col-secondary;
                h6 {
                    color: app.$col-text;
                }
            }
        }
        &-warning {
            background-color: transparent;
            border: 2px solid app.$col-warning;
            h6 {
                color: app.$col-warning;
            }
            &:hover {
                background-color: app.$col-warning;
                h6 {
                    color: app.$col-text;
                }
            }
        }
        &-error {
            background-color: transparent;
            border: 2px solid app.$col-error;
            h6 {
                color: app.$col-error;
            }
            &:hover {
                background-color: app.$col-error;
                h6 {
                    color: app.$col-text;
                }
            }
        }
        &-success {
            background-color: transparent;
            border: 2px solid app.$col-success;
            h6 {
                color: app.$col-success;
            }
            &:hover {
                background-color: app.$col-success;
                h6 {
                    color: app.$col-text;
                }
            }
        }
    }

    .bold {
        h6{
            font-weight: 700;
        }
    }

    .discord {
        background-color: app.$col-accent;
        &:hover {
            background-color: app.$col-secondary;
        }
    }

    .discord::after {
        content: url('@/assets/discord.svg');
        margin-left: 1rem;
    }
</style>