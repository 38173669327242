<template>
    <div :class="'floating-banner bkg-' +  color" :style="'width: ' + width">
        <slot>

        </slot>
    </div>
</template>

<script>
export default {
    name: 'WidthBanner',
    components: {
    },
    props: {
        color: {
            type: String,
            default: 'text'
        },
        width: {
            type: String,
            default: '66%'
        }
    }
}
</script>

<style lang="scss" scoped>
    @use '@/assets/node.scss' as app;
    .floating-banner {
        height: fit-content;
        min-height: 200px;
        margin: 0 auto;
        padding: 2rem;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
        transform: translateY(-8rem);
        z-index: 10;

        @media (max-width: app.$mobile) {
            width: 100vw !important;
            min-height: 50px;
            padding: 1rem;
            padding: 1rem;
            margin: 0;
        }

    }
</style>