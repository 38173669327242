<template>
    <!-- <div class="wrapper" :style="'flex-direction: ' + (vertical ? 'column' : 'row') + '; ' + (reverse ? 'flex-direction: ' + (vertical ? 'column-reverse' : 'row-reverse') : '')"> -->
    <div class="wrapper" :style="{flexDirection: (vertical ? 'column' : 'row')+ (reverse ? '-reverse' : ''), gap: gap ? '1rem' : '0'}">
        <div class="split" :style="{justifyContent: justify, alignItems: align, width: ratio}">
            <slot name="left">
            </slot>
        </div>
        <div class="split" :style="{justifyContent: justify, alignItems: align, width: 'calc(100% - ' + ratio + ')'}">
            <slot name="right">
            </slot>
        </div>

    </div>
</template>

<script>
export default {
    name: 'SplitSection',
    components: {
    },
    props: {
        reverse: {
            type: Boolean,
            default: false
        },
        vertical: {
            type: Boolean,
            default: false
        },
        gap: {
            type: Boolean,
            default: false
        },
        justify: {
            type: String,
            default: 'space-between'
        },
        align: {
            type: String,
            default: 'flex-start'
        },
        ratio: {
            type: String,
            default: '50%'
        }
    }
}
</script>

<style lang="scss" scoped>
    @use '@/assets/node.scss' as app;
    .wrapper{
        width: 100%;
        display: flex;
        
    }
    .split{
        display: flex;
        flex-direction: column;
    }
</style>